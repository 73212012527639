<template>

<app-content :loading="is.loading">

	<app-content-head title="Play to Win" :tabs="tabs" />

	<app-content-body :is-grid="4" :loading="is.loading" :is-empty="!games.length" placeholder="No winners picked yet.">

		<app-card :metrics="metrics(item)" :tab="tab(item)" v-for="item in games" :not-linked="true" :key="item.id" :image="item.game.bgg_game.image" :title="item.name" :subtitle="(item.winner) ? 'Won by ' + item.winner.name : 'Yet to be won'" />

	</app-content-body>

</app-content>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: true
			},
			games: []
		}

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Checkout.Wins', text: 'Overview' },
				{ name: 'Convention.Checkout.Wins.Games', text: 'Games' },
				{ name: 'Convention.Checkout.Wins.Winners', text: 'Winners' }
			]

		}

	},

	created: function() {

		this.load()

	},

	methods: {

		tab: function(item) {

			if (item.user.won) {

				return 'You won!'

			} else if (item.user.played) {

				return 'You played'

			} else {

				return false

			}

		},

		metrics: function(item) {

			return [
				{
					label: 'participant',
					value: item.participants
				},
				{
					label: 'play',
					value: item.plays
				}
			]

		},

		load: function() {

			this.$api.get('convention/checkout/wins/winners').then(function(json) {

				this.games = json.collection

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>